export const PCPICS = [
    {
        id: 0,
        src: "./assets/images/pc-pic2.jpg",
        alt: "PC pic 1",
        caption: "PC pic 1"
    },
    {
        id: 1,
        src: "./assets/images/pc-pic1.jpg",
        alt: "PC pic 2",
        caption: "PC pic 2"
    }

]